<template>
    <div class="w3-container" style="padding-top: 40px;padding-bottom:16px; margin-top:40px" v-bind:style="'height:'+  (screenHeight-40) +'px;'">
        <select v-model="departement" class="w3-select w3-border w3-theme w3-round w3-small" style="width: 100%;" required v-on:change="changeDept($event)">
            <option class="w3-white" v-for="option in dept" v-bind:value="option.value" :key="option.value">
                {{ option.text }}
            </option>
        </select>
        <div class="w3-container w3-theme-l5 w3-round-large w3-padding scrlt-y" v-bind:style="'height:'+  (screenHeight-95) +'px;'">
            <div class="w3-row">
                <div class="w3-col l4 w3-margin-bottom">
                    <div class="w3-row">
                        <div class="w3-col l12 w3-margin-bottom">
                            <div class="w3-container w3-round-large w3-red w3-padding">
                                <div class="w3-left"><i class="fa fa-users w3-xxlarge"></i></div>
                                <div class="w3-right">
                                    <span>Customer / Supplier</span>
                                </div>
                                <div class="w3-clear"></div>
                                <div class="w3-right">
                                    <span class="w3-round-large cursor">{{card.customer}}</span> / <span class="w3-round-large cursor">{{card.supplier}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w3-row">
                        <div class="w3-col l12 w3-margin-bottom">
                            <div class="w3-container w3-round-large w3-blue w3-padding">
                                <div class="w3-left"><i class="fa fa-money w3-xxlarge"></i></div>
                                <div class="w3-right">
                                    <span>Invoice tgl {{day}}</span>
                                </div>
                                <div class="w3-clear"></div>
                                <div class="w3-right">
                                    <span>{{card.invoiceperday}} / {{card.amountinvoiceperday}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w3-row">
                        <div class="w3-col l12 w3-margin-bottom">
                            <div class="w3-container w3-round-large w3-green w3-padding">
                                <div class="w3-left"><i class="fa fa-money w3-xxlarge"></i></div>
                                <div class="w3-right">
                                    <span>Amount Invoice {{month}}</span>
                                </div>
                                <div class="w3-clear"></div>
                                <div class="w3-right">
                                    <span>{{card.invoicepermonth}} / {{card.amountinvoicepermonth | toRp}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w3-row">
                        <div class="w3-col l12">
                        <div class="w3-container w3-round-large w3-orange w3-padding">
                            <div class="w3-left"><i class="fa fa-money w3-xxlarge"></i></div>
                            <div class="w3-right">
                                <span>Sales Order / Sales Invoice</span>
                            </div>
                            <div class="w3-clear"></div>
                            <div class="w3-right">
                                <span class="w3-round-large">{{card.so | toRp}} / {{card.si | toRp}}</span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="w3-col l8 w3-margin-bottom w3-container">
                    <div class="w3-round-small">
                        <header class="w3-container w3-round w3-border-bottom w3-center cursor" style="height: 38px;" id="rp-itemstock">
                            <h4 style="text-transform: uppercase;">5 BEST SELLING ITEMS {{monthSub}}</h4>
                        </header>
                        <div class="w3-row w3-padding-16 w3-col l7">
                            <div class="w3-row">
                                <pie-chart :chartData="chartData" :options="chartOptions" :height="300"></pie-chart>
                            </div>
                        </div>
                        <div class="w3-col l5">
                            <ol>
                                <li v-for="item in recCharts" :key="item.tm_id">
                                    {{ item.tm_name }}
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w3-row">
                <div class="w3-card w3-white w3-round-large">
                    <header class="w3-container w3-center">
                        <h4>INVOICE</h4>
                    </header>
                    <div class="w3-row">
                        <line-chart :chartData="lineData" :options="lineOptions"></line-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import PieChart from "./chart/PieChart.js";
import LineChart from "./chart/LineChart.js";
import moment from "moment";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Main',
    components: {
        PieChart,
        LineChart
    },
    data() {
        return {
            sales_name : localStorage.username,
            screenHeight : 0,
            day : moment(new Date()).format('DD'),
            month : moment(new Date()).format('MMM'),
            monthSub : moment().subtract(1, 'months').format('MMM YYYY'),
            card : {
                customer : 0,
                supplier : 0,
                invoiceperday : 0,
                amountinvoiceperday : 0,
                invoicepermonth : 0,
                amountinvoicepermonth : 0,
                so : 0,
                si :  0
            },
            rectargetdtl: {},
            recCharts : [],
            chartData: {},
            chartOptions: {},
            chartLabel : [],
            lineData : {},
            lineOptions : {},
            LdtNow : [],
            LdtLast : [],
            LineLabel : [],
            departement : 'SPF',
            dept: [
                { text: 'SPF', value: 'SPF' },
                { text: 'SWP', value: 'SWP' },
                { text: 'SWI', value: 'SWI' }
            ]

        }
    },
    methods : {
        changeDept(event){
            this.LoadData();
            this.getDataPie();
            this.getDataLine();
        },
        LoadData(){
            let self = this;
            let param = {
                pfunction : 'selcrontsfl',
                filter : 'main',
                dept: self.departement
            }
            axios.post("dashboard/ApiCront.php",param).then(function (response) {
                self.card.customer = response.data.rec[0].tc_nilai;
                self.card.supplier = response.data.rec[4].tc_nilai;
                self.card.invoiceperday = response.data.rec[2].tc_nilai;
                self.card.amountinvoiceperday = response.data.rec[2].tc_amount;
                self.card.invoicepermonth = response.data.rec[1].tc_nilai;
                self.card.amountinvoicepermonth = response.data.rec[1].tc_amount;
                self.card.so = response.data.rec[3].tc_amount;
                self.card.si = response.data.rec[1].tc_amount;
            });
        },
        getPercen(a, b){
            let percentage = (a * 100 / b).toFixed(2) + '%';
            return percentage;
        },
        setInit() {
            this.LoadData(this.departement);
            this.getDataPie();
            this.getDataLine();
        },
        getDataPie(){
            let self = this;
            let param = {
                pfunction : 'getmostitem',
                dept : self.departement
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.recCharts = response.data.rec;
                self.chartData = {
                    labels: response.data.rec.map(entry => entry.tm_name),
                    datasets: [
                        {
                            anchor: "center",
                            borderWidth: 0,
                            backgroundColor: response.data.rec.map(entry => self.getColor()),
                            data: response.data.rec.map(entry => entry.tm_qty),
                            borderColor: "#FFFFFF",
                            hoverBorderWidth: 5,
                        }
                    ]
                }
            });      
            this.chartOptions = {
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true,
                    callbacks: {
                        // label:function (tooltipItem, data) {
                        //     console.log(data);
                        //     return data['labels'][tooltipItem['index']];
                        // },
                        footer:function (tooltipItem, data) {
                            let sum = 0;
                            let dataset = data.datasets[0];
                            let currentValue = dataset.data[tooltipItem[0].index];
                            let dataArr = dataset.data;
                            dataArr.map(data => {
                                sum += Number(data);
                            });
                            let percentage = (currentValue * 100 / sum).toFixed(2) + '%';
                            return "Persentase "+percentage;
                        }
                    }
                }
            }
        },
        getDataLine(){
            let self = this;
            let param = {
                pfunction : 'selinvoice',
                dept : self.departement
            }
            axios.post("dashboard/ApiDboard.php",param).then(function (response) {
                self.LdtNow = response.data.recNow.map(entry => entry.t_amount);
                self.LdtLast = response.data.recLast.map(entry => entry.t_amount);
                self.LineLabel = response.data.recLast.map(entry => entry.bulan);
                let rdNow = self.getColor();
                let rdLast = self.getColor();

                self.lineData = {
                    labels: self.LineLabel,
                    datasets: [
                        {
                            label: new Date().getFullYear(),
                            data: self.LdtNow,
                            fill: false,
                            backgroundColor : rdNow,
                            borderColor: rdNow,
                            tension: 0.2
                        },
                        {
                            label: new Date().getFullYear()-1,
                            data: self.LdtLast,
                            fill: false,
                            backgroundColor : rdLast,
                            borderColor: rdLast,
                            tension: 0.2
                        },
                    ],
                }
            });
            self.lineOptions = {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(value, index, values) {
                                    return self.toRph(value);
                                }
                            },
                        },
                    ],
                },
                tooltips: {
                    mode: 'index',
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function(c, i, a) {
                                return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
                            });
                        },
                        footer: (ttItem) => {
                            let percen = 0;
                            if (ttItem.length == 1) {
                                percen = 100+'%';
                            }else{
                                let a = parseFloat(ttItem[0]['yLabel']);
                                let b = parseFloat(ttItem[1]['yLabel']);
                                if (a > b) {
                                    percen = ((a-b)/a)*100;
                                }else{
                                    percen = ((b-a)/b)*100;
                                }
                                percen = percen.toFixed(2) + '%';
                            }
                            return "Selisih : "+percen;
                        }
                    }
                }                
            }
        },
        getColor(){
            let code = '#' + Math.floor(Math.random() * 16777215).toString(16);
            return code;            
        },
        toRph(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    filters: {
        toRp(value) {
            return `${Intl.NumberFormat("de-DE").format(value)}`;
        },
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>
